import React, { Fragment } from 'react';
import { compose } from 'recompose';

import Layout from '../App/components/layout';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';


import UpdateEmail from '../components/UpdateProfile/updateEmail';


import SEO from "../App/components/seo"

const EmailPageBase = () => (

    <Fragment>
      <div class="wrapper maxWidth"><h1>Update Email</h1>
      <UpdateEmail/>
      </div>
    </Fragment>

  );

  const condition = authUser => !!authUser;

  const EmailPage = compose(
    withEmailVerification,
    withAuthorization(condition),
  )(EmailPageBase);

export default () =>(
    <Layout>
      <SEO title="" />
      <EmailPage/>
    </Layout>
  );
